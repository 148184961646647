/* Desktop */
.navbar {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;

    background-color: #2c3e50;
    padding: 10px 0;
    font-family: 'Arial', sans-serif;
    height: 4.6%;
    padding-top: 25px;
    padding-bottom: 25px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.navbar-brand {
    color: #ecf0f1;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
}

.navbar-brand-logout {
    color: #ecf0f1;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
}

.brand-logo-logout {
    height: 35px !important;
    width: auto !important;
    margin-right: -50%;
    margin-top: 0;
}

.brand-logo {
    height: 35px !important;
    width: auto !important;
    margin-right: -43%;
    margin-top: 0;
}


.navbar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.language-item {
    display: flex;
    align-items: center;
}

.language-button {
    background: none;
    border: none;
    color: #ecf0f1;
    padding-right: 25px;
    cursor: pointer;
    font-size: 1em;
}

.language-button.active {
    font-weight: bold;
}

.navbar-item {
    font-size: 1.15em;
}

.logout-container {
    font-size: 1.15em;
}

.navbar-link {
    color: #ecf0f1;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.navbar-link:hover {
    background-color: #34495e;
}

.navbar-link i {
    margin-right: 5px;
}

.menu-toggle {
    display: none;
}

/* Προσθήκη slide-in effect */
@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

/* smartphone */
@media (max-width: 992px) {
    .navbar {
        height: 4.8%;
    }

    .menu-toggle {
        font-size: 24px;
        color: #ecf0f1;
        cursor: pointer;
        align-self: flex-start;
        display: block;
        order: -1;
        padding-right: 15px;
        position: absolute;
        right: 15px;
    }

    .navbar-brand {
        margin-bottom: 3%;
        margin-left: 57%;
    }

    .navbar-brand-logout {
        margin-bottom: 3%;
        margin-left: 52%;
    }

    .brand-logo {
        height: 35px !important;
        width: auto !important;
        margin-left: 0%;
        margin-top: -1%;
        padding-bottom: 3%;
        margin-right: -55%;
    }

    .brand-logo-logout {
        height: 35px !important;
        width: auto !important;
        margin-left: 0%;
        margin-top: -1%;
        padding-bottom: 3%;
    }

    .navbar-menu {
        z-index: 100;
        position: absolute;
        top: 90%;
        left: -100%;
        width: 70%;
        height: 85.8vh;
        background-color: #2c3e50;
        opacity: 1;
        text-align: left;
        padding: 15px;
        transition: left 0.3s ease-out;
        transform: translateX(0);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        display: none;
    }

    .navbar-menu.show {
        animation: slideIn 0.3s forwards;
        /* Προσθήκη animation */
        display: flex;
        left: 0;
        transform: translateX(0%);
    }

    .navbar-item {
        font-size: 1.4em;
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .logout-container {
        font-size: 1.4em;
        display: block;
        position: absolute;
        bottom: 0;
        width: 80%;
        background-color: #2c3e50;
        padding: 7% 0;
    }

    .logout-link {
        display: block;
        width: 100%;
        text-align: left;
    }

    .container {
        justify-content: flex-start;
    }
}