.social-share-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f4f4f4;
    border-radius: 10px;
}

.social-share-buttons a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px;
    transition: transform 0.3s;
}

.social-share-buttons a:hover {
    transform: scale(1.1);
}

/* Social network specific colors */
.social-share-buttons a[href*="facebook"] {
    background-color: #1877f2;
    color: white;
}

.social-share-buttons a[href*="twitter"] {
    background-color: #1da1f2;
    color: white;
}

.social-share-buttons a[href*="whatsapp"] {
    background-color: #25d366;
    color: white;
}

.social-share-buttons a[href*="viber"] {
    background-color: #665cac;
    color: white;
}

.social-share-buttons a[href*="fb-messenger"] {
    background-color: #0084ff;
    color: white;
}

.property-details {
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
    /* padding: 20px; */
    /* padding-top: 6%; */
}

#detail-title {
    font-size: 22px;
    margin-bottom: 15px;
    color: #2c3e50;
    font-weight: 250;
}

.property-details p {
    font-size: 16px;
    margin-bottom: 10px;
    /* color: #666; */
}

.property-details p.with-edit-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.property-details p.with-edit-btn .edit-btn {
    margin-left: 10px;
    /* Προσθήκη οριακών για εμφάνιση κοντά στη δεξιά πλευρά του παραγράφου */
}

.property-details .edit-btn {
    display: inline-block;
    padding: 8px 15px;
    background-color: #2c3e50;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 10px;
}

.property-details .edit-btn:hover {
    background-color: #4d6d8d;
}

.youtube-video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* Aspect ratio 16:9 */
    height: 0;
    overflow: hidden;
}

.youtube-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.price {
    font-weight: bold;
    color: #2c7be5;
}

.status {
    font-weight: bold;
    color: #e63946;
}

.property-photos {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 20px;
}

.property-photos img {
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.property-photos img:hover {
    transform: scale(1.1);
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.image-modal img {
    max-width: 90%;
    max-height: 80%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s;
}

.image-modal img:hover {
    transform: scale(1.05);
}

.image-modal .close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}

.image-modal .prev,
.image-modal .next {
    font-size: 24px;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 20px;
    cursor: pointer;
}

.image-modal .prev {
    left: 10px;
}

.image-modal .next {
    right: 10px;
}

@media (max-width: 768px) {
    .property-details {
        padding-top: 15%;
        padding: 20px;
    }

    .property-details h1,
    .property-details p {
        text-align: center;
    }

    h2 {
        text-align: center;
    }
}