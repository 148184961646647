.client-edit-container {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    padding-top: 6%;
}

.client-edit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.client-edit-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    color: #666;
}

.form-group input, 
.form-group textarea {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.form-group textarea {
    resize: vertical;
    height: 100px;
}

.btn {
    padding: 8px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #2c3e50;
    color: #fff;
}

.btn:hover {
    background-color: #1a2836;
}

.btn-danger {
    background-color: #dc3545;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px; /* Προσθήκη ελαφρώς αριστερού περιθωρίου για καλύτερη αισθητική */
    border-radius: 4px; /* Ορισμός γωνίας για λείες γωνίες */
}

/* Ορισμός ενός hover state για το κουμπί, προσθέτοντας λίγο σκοτείνιασμα */
.btn-danger:hover {
    background-color: #c82333;
}


@media (max-width: 768px) {
    .client-edit-container {
        padding-top: 15%;
    }
}
