/* SocialShareButtons.css */

.social-share-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* background-color: #f4f4f4; */
    /* border-radius: 10px; */
  }
  
  .social-share-buttons a, .social-share-buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px;
    transition: transform 0.3s;
    /* background-color: #f4f4f4; */
    border: none;
    cursor: pointer;
  }
  
  .social-share-buttons a:hover, .social-share-buttons button:hover {
    transform: scale(1.1);
  }
  
  /* Social network specific colors */
  .social-share-buttons a[href*="facebook"] {
    background-color: #1877f2;
    color: white;
  }
  
  .social-share-buttons a[href*="twitter"] {
    background-color: #1da1f2;
    color: white;
  }
  
  .social-share-buttons a[href*="wa"] {
    background-color: #25d366;
    color: white;
  }
  
  .social-share-buttons a[href*="viber"] {
    background-color: #665cac;
    color: white;
  }
  
  .social-share-buttons button {
    background-color: #5e9bd8;
    color: white;
  }
  
  .social-share-buttons button:hover {
    transform: scale(1.1);
    background-color: white;
    color: #5e9bd8;
  }
  