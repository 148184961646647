.background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
}

.register-container {
    display: flex;
    position: relative;
    z-index: 1;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.register-title {
    color: white;
    padding-top: 7rem;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.register-input {
    width: 100%;
    max-width: 300px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.register-button {
    margin-top: 0.5rem;
    width: 100%;
    max-width: 300px;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    background-color: #2c3e50;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.register-button:hover {
    background-color: #476584;
}

.register-message {
    width: 100%;
    max-width: 300px;
    padding: 0.5rem;
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    border-radius: 4px;
    background-color: #e74c3c; /* Κόκκινο χρώμα φόντου */
    color: #fff; /* Λευκό χρώμα κειμένου */
    transition: background-color 0.3s ease;
}

.register-message.success {
    background-color: #2ecc71; /* Πράσινο χρώμα φόντου */
}

/* Media query για μεγάλες οθόνες */
@media (min-width: 768px) {
    .register-container {
        max-width: 600px;
        margin: 0 auto;
    }

    .register-input {
        font-size: 1.2rem;
        padding: 0.75rem;
    }

    .register-button {
        font-size: 1.2rem;
        padding: 0.75rem;
    }
    .register-message {
        font-size: 1.2rem;
        padding: 0.75rem;
    }
}
