/* Βασικά στυλ για την αρχική σελίδα */
.home-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

/* Εφαρμογή fade effect στην φωτογραφία */
.hero-image {
    position: absolute;
    /* top: 0;
    left: 0; */
    width: 100vw;
    height: 91.5%;
    object-fit: cover;
    z-index: 0;
    filter: brightness(50%);
    /* Χρήση φίλτρου για να εφαρμοστεί το fade effect */
}

/* Στυλ για το περιεχόμενο πάνω στη φωτογραφία */
.content {
    position: relative;
    z-index: 1;
    color: #2c3e50;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85vh;
}

h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.filters-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    gap: 20px;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.type-filter,
.price-filter,
.square-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    flex-wrap: wrap;
    /* Ενεργοποίηση της ικανότητας να "σπάνε" σε νέες γραμμές */
    margin-top: 1em;
    /* Προσθήκη ελαφρύς κενού κάτω από κάθε σετ φίλτρων */
}

input,
select {
    margin: 5px;
    padding: 12px 16px;
    /* Αύξηση padding */
    font-size: 14px;
    /* Αύξηση μεγέθους γραμματοσειράς */
    border: 2px solid #2c3e50;
    border-radius: 8px;
    /* Κυκλικές γωνίες */
    transition: all 0.3s ease;
    /* Προσθήκη transition για smooth hover effect */
}

input:focus,
select:focus {
    border-color: #3498db;
    /* Αλλαγή χρώματος όταν το πεδίο είναι focused */
    box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
    /* Προσθήκη σκιάς όταν το πεδίο είναι focused */
}

.actions {
    margin-top: 1.5%;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.search-button {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    /* Μπορείτε να προσαρμόσετε αυτήν την τιμή */
}

/* Αλλαγές στα στυλ των κουμπιών */
.btn-primary {
    background-color: #3498db;
    color: #ffffff;
}

.btn-primary:hover {
    background-color: #2980b9;
}

.btn-selected {
    background-color: #e74c3c !important;
}

.btn {
    padding: 10px 20px;
    border: none;
    background-color: #2c3e50;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
}

.btn:hover {
    background-color: #34495e;
}

.about-us {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.about-us-image {
    margin-left: 2rem;
    width: 56%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
}

.tips-image {
    margin-right: 2rem;
    width: 39%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
}

.about-us-content {
    width: 55%;
    margin-right: 2rem;
    margin-left: 2rem;
}

h2 {
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 1rem;
}

p {
    font-size: 1rem;
    color: #2c3e50;
    line-height: 1.5;
}

/* Προσαρμογή για μικρές οθόνες (smartphones) */
@media (max-width: 576px) {
    .content {
        height: 78vh;
    }

    .hero-image {
        height: 90vh;
    }

    h1 {
        font-size: 1.5rem;
    }

    input,
    select {
        font-size: 14px;
    }

    .type-filter,
    .price-filter,
    .square-filter {
        flex-direction: column;
        align-items: center;
    }

    .btn {
        font-size: 14px;
    }

    .about-us {
        flex-direction: column;
        text-align: center;
    }

    .about-us-image {
        width: 100vw;
        max-width: none;
        margin-right: 2rem;
    }

    .tips-image {
        width: 100vw;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
        margin-left: 2rem;
    }

    .about-us-content {
        width: 100%;
    }

    #tips {
        flex-direction: column-reverse;
    }

    .type-filter,
    .price-filter,
    .square-filter {
        margin-top: 0em;
    }
}