.footer {
    background-color: #2c3e50;
    padding: 20px 0;
    color: #ffffff;
    font-family: Arial, sans-serif;
}

.footer-content {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.footer-section {
    width: 50%;
    text-align: center;
}

.footer-section h4 {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
}

.contact-list {
    list-style: none;
    padding: 0;
}

.contact-list li {
    margin-bottom: 1rem;
}

.contact-list a {
    color: #ffffff;
    text-decoration: none;
}

.contact-list a:hover {
    text-decoration: underline;
}

.footer-bottom {
    text-align: center;
    margin-top: 1.5rem;
}

.footer-bottom p {
    color: rgb(174, 174, 174);
    font-size: 0.8rem;
}

.fas,
.fab {
    margin-right: 0.5rem;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .footer-section {
        width: 80%;
    }

    .footer-section h4 {
        font-size: 1.2rem;
    }

    .footer-bottom p {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 480px) {
    .footer-section {
        width: 100%;
    }
}
