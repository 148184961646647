.container-clients {
    margin: 2.4% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 1400px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding-top: 4%;
}

.property-list-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.property-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;       
    justify-content: flex-start;  
    text-decoration: none;
    color: inherit;
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    transition: background-color 0.3s ease;
}

.property-link:hover {
    background-color: #e9e9e9;
}

.client-grid {
    display: flex;
    flex-direction: column;
    align-items: stretch; 
    width: 100%;
}

.add-client-btn {
    margin: 15px 0;
    padding: 10px 20px;
    border: none;
    background-color: #2c3e50;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-client-btn:hover {
    background-color: #405c78;
}

/* Responsive Design for narrower screens */
@media (max-width: 1200px) {
    .property-link {
        flex: 1 0 calc(50% - 20px); 
    }
    .client-grid {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .container-clients {
        padding-top: 22%;
    }
    .property-link {
        flex: 1 0 100%;
        margin: 10px 0;
    }
    .client-grid {
        justify-content: center;
    }
}
